import "./src/style/variables.css"
import "./src/style/fonts.css"
import "./src/style/common.css"

function scrollTo(document, id) {
  const element = document.getElementById(id)
  if (element) {
    element.scrollIntoView()
    window.scrollBy(0, -64) // Shift for sticky menu bar
    return true
  }
  return false
}

// Workaround for Gatsby bug
// https://github.com/gatsbyjs/gatsby/issues/38201
export const shouldUpdateScroll = ({ prevRouterProps, routerProps }) => {
  if (routerProps.location.pathname.match(/\/case-studies\/.+\//g)) {
    // Handle special case for case studies
    // https://github.com/code-and-co/codeandco-web-new/issues/64
    if (!prevRouterProps || !prevRouterProps.location.pathname.match(/\/case-studies\/.+/g)) {
      return !scrollTo(document, "discover-types")
    } else if (!document.getElementById("discover-types")) {
      return true
    }
    return false
  }
  if (routerProps.location.hash) {
    scrollTo(document, routerProps.location.hash.slice(1))
    return false
  }
  return true
}

// Handle special case for case studies
// https://github.com/code-and-co/codeandco-web-new/issues/64
export const onRouteUpdate = ({ prevLocation, location }) => {
  if (!prevLocation && location.pathname.match(/\/case-studies\/.+\//g))
    scrollTo(document, "discover-types")
}
